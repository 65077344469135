<template>
    <b-container fluid>
        <b-modal id="modal-add-edit-plan" ref="modal-add-edit-plan" :title="modalTitle" hide-footer @show="initModal" @hidden="add_new_plan = false">

            <b-form @submit="onSubmit" class="p-2">

                <b-row>
                    <b-col lg="6" md="6">
                        <b-form-group>
                            <div
                                class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
                                <div class="custom-switch-inner">
                                    <label class="mr-2">Status</label>
                                    <input type="checkbox" class="custom-control-input bg-primary" v-model="form.active"
                                        id="customSwitch-13">
                                    <label class="custom-control-label" for="customSwitch-13" data-on-label="On"
                                        data-off-label="Off">
                                    </label>
                                </div>
                            </div>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-form-group id="input-group-1" label="Name:" label-for="input-1" description="">
                    <b-form-input id="input-1" v-model="form.name" type="text" placeholder="plan name"
                        required></b-form-input>
                </b-form-group>

                <b-form-group id="input-group-2" label="Interval:" label-for="input-2" description="">

                    <multiselect ref="interval-ref" tag-placeholder="Interval" placeholder="Interval"
                        v-model="form.interval" :options="intervals" :multiple="false">
                    </multiselect>

                </b-form-group>


                <b-form-group id="input-group-2" label="Service:" label-for="input-2" description="">

                    <multiselect placeholder="Choose service" :taggable="true" v-model="service" :options="services"
                        label="name" track-by="code" :multiple="false">
                    </multiselect>

                </b-form-group>


                <b-form-group id="input-group-2" label="Default options:" label-for="input-2" description="">

                    <multiselect ref="default-options-ref" tag-placeholder="Default options"
                        placeholder="Default options" v-model="form.options" :options="plan_default_options"
                        :multiple="true">
                    </multiselect>

                </b-form-group>

                <b-form-group id="input-group-4" label="Initial Balance:" label-for="input-4" description="">
                    <b-form-input id="input-1" v-model="form.balance" type="text" placeholder="Initial balance"
                        required></b-form-input>
                </b-form-group>

                <b-row>
                    <b-col lg="6" md="6">
                        <b-form-group id="input-group-3" label="Initial price:" label-for="input-3" description="">
                            <b-form-input id="input-1" v-model="form.price" type="text" placeholder="Initial price"
                                required></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col lg="6" md="6">
                        <b-form-group id="input-group-3" label="price/unit:" label-for="input-3" description="">
                            <b-form-input id="input-1" v-model="form.unit_price" type="text"
                                placeholder="Unit/price"></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-button class="pull-right" variant="primary" type="submit" :disabled="isLoading">
                    Submit
                    <b-spinner small type="grow" v-show="isLoading"></b-spinner>
                </b-button>
            </b-form>

        </b-modal>
    </b-container>
</template>


<script>

import api from "@/api";
import { mapGetters } from "vuex";
import {SERVICES} from "@/constantes";
import Multiselect from 'vue-multiselect'

export default {
    name: 'ModalAddEditPlan',
    props: {
        item: {
            type: Object,
            default: null
        },
        serviceId: {
            type: Number,
            default: null
        },
    },
    components: {
        Multiselect
    },
    data() {
        return {
            isLoading: false,
            planId: null,
            add_new_plan: false,
            existed_options:[],

            form: {
                id: null,
                name: null,
                active: false,
                stripe_plan: null,
                parent_id: null,
                price: null,
                balance: null,
                unit_price: null,
                interval: "month",
                service_id: this.service ? this.service.code : SERVICES.LIVE_OPENS.id,
                options: []
            },
            intervals: [
                "monthly"
            ],
            services: [
                {code: SERVICES.LIVE_OPENS.id, name: SERVICES.LIVE_OPENS.name},
                {code: SERVICES.LIVE_LEADS.id, name: SERVICES.LIVE_LEADS.name},
                {code: SERVICES.LIVE_VALIDATION.id, name: SERVICES.LIVE_VALIDATION.name},
            ],
            service: {
              code : SERVICES.LIVE_OPENS.id,
              name : 'LiveOpens'
            },
        }
    },
    mounted() {
        this.initPlanFormModal();
    },
    methods: {
        initModal() {
            this.initService();
            this.initPlanFormModal();
        },
        initService() {
            // Found and set current service
            if(this.serviceId) {
                const service = this.services.find(o => o.code === this.serviceId);
                if(service) this.service = service;
            }
        },
        initPlanFormModal() {
            this.isLoading = true;
            this.form = {};
            let current_options = [];

            this.add_new_plan = this.item && this.item?.id ? false : true;

            if(!this.item) {

                let form = {
                    "name": null,
                    "id": null,
                    "active": false,
                    "stripe_plan": null,
                    "parent_id": null,
                    "price": null,
                    "balance": null,
                    "unit_price": null,
                    "interval": "month",
                    "service_id": this.service ? this.service.code : SERVICES.LIVE_OPENS.id,
                    "options": []
                };

                this.form = form;

                let options = this.existed_options[this.service.code];

                if(typeof options != 'undefined') {
                    Object.keys().forEach(key => {
                        if (this.existed_options[this.service.code][key]) {
                            current_options.push(key);
                        }
                    });
                }
                
            } else {
                this.planId = this.item?.id;
                let parent_id = null;

                if (this.item?.parent) parent_id = this.item?.parent?.id;

                if (this.item?.options) {

                    let options = JSON.parse(this.item.options)

                    Object.keys(options).forEach(key => {
                        if (options[key]) {
                            current_options.push(key);
                        }
                    });
                }

                this.form = {
                    "name": this.item.name,
                    "id": this.item.id,
                    "active": this.item.active,
                    "price": this.item.price,
                    "parent_id": parent_id,
                    "interval": this.item.interval,
                    "balance": this.item.balance,
                    "unit_price": this.item.unit_price,
                    "service_id": this.item.service_id,
                    "options": current_options
                };
                this.service = {
                    code: this.item.service.id,
                    name: this.item.service.name
                }
            }

            this.form.options = current_options;
            this.isLoading = false;

        },
        onSubmit(e) {

            e.preventDefault();

            this.isLoading = true;
            const planId = this.form?.id;
            const data = this.form;

            if (this.add_new_plan) {

                api.plans.post(data).then(response => {

                    this.isLoading = false;
                    let variant = 'danger';
                    let message = 'Something went wrong'

                    if ('success' in response) {
                        variant = response.success ? 'success' : 'danger';
                        message = response.message;
                        this.toast('plan', variant, message);
                        
                        this.$emit('hide');
                        this.$root.$emit('bv::hide::modal', 'modal-add-edit-plan');
                    }

                }).catch(err => {
                    this.$bvToast.toast(err.response.data.message, {
                        title: 'Error',
                        variant: 'danger',
                    });
                })

            } else {

                api.plans.put(planId, data).then(response => {
                    this.isLoading = false;
                    let variant = 'danger';
                    let message = 'Something went wrong'

                    if ('success' in response) {
                        variant = response.success ? 'success' : 'danger';
                        message = response.message;
                        this.toast('plan', variant, message);

                        this.$emit('hide');
                        this.$root.$emit('bv::hide::modal', 'modal-add-edit-plan');
                    }
                }).catch(err => {
                    this.$bvToast.toast(err.response.data.message, {
                        title: 'Error',
                        variant: 'danger',
                    });
                })

            }

        },
        getForm() {
            return this.form
        },
        toast(title, variant, message) {
            this.$bvToast.toast(message, { title, variant});
        }
    },
    computed: {
        ...mapGetters('Auth', ['user', 'isAdmin']),
        modalTitle() {
            return this.add_new_plan ? 'Create a new plan' : 'update an Existing plan';
        },
        plan_default_options() {

            if (this.existed_options[this.service.code]) {


                let current_options = [];
                Object.keys(this.existed_options[this.service.code]).forEach(key => {

                    current_options.push(key);

                });

                return current_options;
            }

            return [];
        },
    }
}

</script>